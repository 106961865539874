var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"card "},[_c('div',{staticClass:"card-body"},[_c('h4',[_vm._v("Classes")]),_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"hide-default-footer":true,"items-per-page":-1,"items":_vm.mergedClasses},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("/report/course/" + (item.course_id) + "/class/" + (item.id))}},[_vm._v(" "+_vm._s(item.title)+" ")])]}},{key:"item.attendance",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(((item.attendance || 0) * 100).toFixed(2))+"% ")]}},{key:"item.retention",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(((item.retention || 0) * 100).toFixed(2))+"% ")]}},{key:"item.preserved",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.preserved)+"/"+_vm._s(item.quota)+" ("+_vm._s(item.preserved > 0 ? (item.preserved / item.quota * 100).toFixed(0) : 0)+"%) ")]}},{key:"item.applied",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.applied)+"/"+_vm._s(item.quota)+" ("+_vm._s(item.applied > 0 ? (item.applied / item.quota * 100).toFixed(0) : 0)+"%) ")]}},{key:"item.paid",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.paid)+"/"+_vm._s(item.quota)+" ("+_vm._s(item.paid ? (item.paid / item.quota * 100).toFixed(0) : 0)+"%) ")]}},{key:"item.remaining",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.quota - item.paid - item.preserved)+"/"+_vm._s(item.quota)+" ("+_vm._s((item.quota - item.paid - item.preserved) > 0 ? ((item.quota - item.paid - item.preserved) / item.quota * 100).toFixed(0) : 0)+"%) ")]}}])})],1)])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }