import moment from 'moment'

export enum PeriodStep {
    DAY,
    WEEK,
    MONTH
}

export class Chart {
    static formatPeriodLabel(curr, first, end, period: PeriodStep) {
        switch (period) {
            case PeriodStep.DAY:
                return moment(curr).format('DD/MM')
            case PeriodStep.WEEK:
                const weekStart = moment(first).isAfter(moment(curr).startOf('week')) ? moment(first) : moment(curr).startOf('week')
                const weekEnd = moment(end).isBefore(moment(curr).endOf('week')) ? moment(end) : moment(curr).endOf('week')
                return `${weekStart.format('DD/MM')} - ${weekEnd.format('DD/MM')}`
            case PeriodStep.MONTH:
                return moment(curr).format('MMM')
        }
    }

    static generatePeriodStart(start, end, period: PeriodStep): number[] {
        const unit = this.transformUnit(period)
        const unitOfTime = this.transformUnitOfTime(period)

        //  calculate different between two date given period step
        const diff = moment(moment(end).endOf(unitOfTime)).diff(moment(start).startOf(unitOfTime), unitOfTime) + 1

        const startData = moment(start).startOf(unit)
        return Array(diff).fill(0).map((_, index) => {
            return moment(startData).add(index, unit).valueOf()
        })
    }


    static transformUnit(period: PeriodStep) {
        switch (period) {
            case PeriodStep.DAY:
                return 'day'
            case PeriodStep.WEEK:
                return 'week'
            case PeriodStep.MONTH:
                return 'month'
        }
        return 'month'
    }

    static transformUnitOfTime(period: PeriodStep) {
        switch (period) {
            case PeriodStep.DAY:
                return 'days'
            case PeriodStep.WEEK:
                return 'weeks'
            case PeriodStep.MONTH:
                return 'months'
        }
        return 'months'
    }
}
