





















































import {CourseType} from '~/components/data-class/data-class'
import {createRequest} from '~/utils/network-request'
import _ from 'lodash'
import {Component, Prop, Vue} from 'vue-property-decorator'
import {VCol, VContainer, VDataFooter, VDataTable, VRow} from 'vuetify/lib'

@Component({
    components: {
        VContainer,
        VRow,
        VCol,
        VDataTable,
        VDataFooter
    }
})
export default class Classes extends Vue {
    @Prop() courseId: string
    @Prop() version: number
    @Prop() courseType: CourseType


    //  META DATA

    //  UI DATA
    tableHeaders = [
        {text: 'ID', value: 'id', sortable: false},
        {text: 'Class', value: 'title', sortable: false},
        {text: 'Avg. attendance', value: 'attendance', sortable: false},
        {text: 'Retention rate', value: 'retention'},
        {text: 'Reserved', value: 'preserved'},
        {text: 'Applied', value: 'applied', sortable: false},
        {text: 'Paid', value: 'paid'},
        {text: 'Remaining', value: 'remaining'}
    ]

    //  DATA
    classes: any[] = []

    attendances: any = {}
    retention: any = {}

    get mergedClasses() {
        let classList = this.classes
        if (!!this.attendances.classes) {
            classList = _.map(classList, item => {
                const targetClass = _.find(this.attendances.classes, {class_id: item.id})
               return  _.extend({}, item, {attendance: targetClass.percentage ? targetClass.percentage : 0})
            })
        }

        if (!!this.retention.classes) {
            classList = _.map(classList, item => {
                const targetClass = _.find(this.retention.classes, {class_id: item.id})
                return  _.extend({}, item, {retention: targetClass.percentage ? targetClass.percentage: 0})
            })
        }
        return classList
    }

    async created() {
        await this.fetchCourseClasses()
        await this.fetchCourseDashboardOffline()
    }

    async fetchCourseClasses() {
        const res = await createRequest(`/course/${this.courseId}/class/classes`, 'get', {version: this.version}).send()
        this.classes = res.data.classes
    }

    async fetchCourseDashboardOffline() {
        const res = await createRequest(`/dashboard/course/${this.courseId}/offline`, 'get', {version: this.version}).send()
        this.attendances = res.data.attendances || {}
        this.retention = res.data.retention || {}
    }
}

