

















































































































import BarChart from '~/components/chart/bar-chart.vue'
import CourseData from '~/components/course/course-data'
import {Course, CourseType} from '~/components/data-class/data-class'
import DatePickerShortcutMixin from '~/mixins/date-picker-shortcut-mixin'
import {Chart, PeriodStep} from '~/utils/chart'
import {createRequest} from '~/utils/network-request'
import _ from 'lodash'
import moment from 'moment'
import {mixins} from 'vue-class-component'
import {Component, Prop, Watch} from 'vue-property-decorator'
import {VChip, VChipGroup, VCol, VContainer, VOverlay, VProgressCircular, VRow} from 'vuetify/lib'

@Component({
    components: {
        VChipGroup,
        VChip,
        VContainer,
        VRow,
        VCol,
        BarChart,
        VProgressCircular,
        VOverlay
    }
})
export default class Performance extends mixins(DatePickerShortcutMixin) {
    @Prop() courseId: string
    @Prop() version: number
    @Prop() courseType: CourseType


    //  META DATA
    CourseType = CourseType
    periodOption: string[] = ['Class', 'Day', 'Week', 'Month']


    //  UI DATA
    isLoading: boolean = false
    selDateRange: number[] = [0, 0]
    realDateRange: number[] = [0, 0]

    selNbStuOption: number = 0
    selRevenueOption: number = 0
    selConversionOption: number = 0

    totalRevenue: number = 0
    revenueCat: string[] = []

    totalNbStu: number = 0
    nbStuCat: string[] = []

    totalAtt: string = ''
    attCat: string[] = []

    totalProgress: string = ''
    progressCat: string[] = []

    totalConvApplied: string = ''
    totalConvPaid: string = ''
    convCat: string[] = []

    //  DATA
    course: Course = new Course()

    // --   Common
    paid_records: any[] = []

    //  --  Live
    attendances: any = {}
    retention: any[] = []

    //  --  Online
    progress: any[] = []

    //  --  Trial
    conversion: any[] = []

    get pickerOptions(): { shortcuts: Array<{ text: string, onClick: {} }> } {
        return {
            shortcuts: this.datePickerShortcuts
        }
    }

    get avaPeriodOption() {
        if (this.courseType === CourseType.ONLINE) {
            const copy = [...this.periodOption]
            return _.pull(copy, 'Class')
        }
        return [...this.periodOption]
    }

    get revenueChartConfig() {
        return {
            chart: {
                type: 'bar'
            },
            dataLabels: {
                enabled: false
            },
            xaxis: {
                categories: (this.selRevenueOption === 0 && this.courseType === CourseType.TRIAL) ? this.convCat : this.revenueCat
            },
            tooltip: {
                shared: true,
                followCursor: true,
                y: {
                    title: {
                        formatter: (seriesName) => '',
                    },
                    formatter: (value) => '$' + value
                },
            }
        }
    }

    get revenueData() {
        const result = this.preparePaidRecordsData(this.avaPeriodOption[this.selRevenueOption], (item) => {
            return item.amount || 0
        })

        if (result) {
            this.revenueCat = result['category']
            return [{
                name: 'Revenue',
                data: result['data']
            }]
        } else {
            return [{data: []}]
        }
    }

    get nbStuChartConfig() {
        const isClass = this.avaPeriodOption[this.selNbStuOption] === 'Class'
        return {
            chart: {
                type: isClass ? 'donut' : 'bar'
            },
            dataLabels: {
                enabled: false
            },
            yaxis: {
                labels: {
                    formatter: function(val) {
                        return val.toFixed(0)
                    }
                }
            },
            labels: (this.selNbStuOption === 0 && this.courseType === CourseType.TRIAL) ? this.convCat : this.nbStuCat,
            tooltip: {
                shared: true,
                followCursor: true,
                y: {
                    title: {
                        formatter: (seriesName) => isClass ? seriesName : '' + ' Student'
                    },
                    formatter: (value) => value
                }
            }
        }
    }

    get nbStuData() {
        const period = this.avaPeriodOption[this.selNbStuOption]
        const result = this.preparePaidRecordsData(period, (item) => {
            return 1
        })

        let series
        if (result) {
            this.nbStuCat = result['category']
            if (period === 'Class')
                series = result['data']
            else
                series = [{data: result['data']}]
        } else {
            if (period === 'Class')
                series = []
            else
                series = [{data: []}]
        }
        return series
    }

    get attChartConfig() {
        return {
            chart: {
                type: 'bar',
                height: 180
            },
            dataLabels: {
                enabled: false
            },
            xaxis: {
                categories: (this.selNbStuOption === 0 && this.courseType === CourseType.TRIAL) ? this.convCat : this.attCat,
            },
            tooltip: {
                shared: true,
                followCursor: true,
                y: {
                    title: {
                        formatter: (seriesName) => '',
                    },
                    formatter: (value) => value.toFixed(2) + '%'
                }
            }
        }
    }

    get attData() {
        if (!this.attendances || _.isEqual(this.attendances, {})) {
            return [{data: []}]
        }

        this.totalAtt = (this.attendances.course.percentage ? this.attendances.course.percentage * 100 : 0).toFixed(2)

        this.attCat = _.map(this.attendances.classes, item => {
            return `[${item.class_id}]`
        })

        return [{
            name: 'Attendance',
            data: _.map(this.attendances.classes, item => item.percentage * 100)
        }]
    }

    get progressData() {
        if (!this.progress.length) {
            this.totalProgress = '0'
            return [{data: []}]
        }
        this.totalProgress = (_.sum(this.progress) / this.progress.length * 100).toFixed(2)
        this.progressCat = ['0%', '> 0 - < 50%', '>= 50% - < 80%', '>= 80% - < 100%', '100%']
        const grouped = _.groupBy(this.progress, item => {
            if (item === 1)
                return 4
            else if (item >= 0.8)
                return 3
            else if (item >= 0.5)
                return 2
            else if (item === 0)
                return 0
            else
                return 1
        })

        const patched = Object.assign({0: [], 1: [], 2: [], 3: [], 4: []}, grouped)
        const counted = _.map(_.values(patched), item => item.length)
        return [{name: 'Student', data: counted}]
    }

    get convData() {
        if (!this.conversion.length) {
            this.totalConvApplied = ''
            this.totalConvPaid = ''
            return [{data: []}, {data: []}]
        }

        this.convCat = _.map(this.conversion, item => {
            return `[${item.id}]`
        })

        let applied, paid


        if (this.selConversionOption === 0) {
            applied = _.map(this.conversion, item => item.applied_conversion / item.total * 100)
            paid = _.map(this.conversion, item => item.paid_conversion / item.total * 100)
        } else {
            applied = _.map(this.conversion, 'applied_conversion')
            paid = _.map(this.conversion, 'paid_conversion')
        }

        console.log(_.sumBy(this.conversion, 'applied_conversion'), _.sumBy(this.conversion, 'paid_conversion'), _.sumBy(this.conversion, 'total'))

        this.totalConvApplied = (_.sumBy(this.conversion, 'applied_conversion') / _.sumBy(this.conversion, 'total')).toFixed(2)
        this.totalConvPaid = (_.sumBy(this.conversion, 'paid_conversion') / _.sumBy(this.conversion, 'total')).toFixed(2)

        if (this.selConversionOption === 0) {
            this.totalConvApplied = this.totalConvApplied + '%'
            this.totalConvPaid = this.totalConvPaid + '%'

        }

        return [
            {data: applied, name: 'Applied'}, {data: paid, name: 'Paid'}
        ]
    }

    async created() {
        this.course = await CourseData.shouldGetCourse(this.courseId)
        if ([CourseType.LIVE, CourseType.TRIAL].includes(this.courseType)) {
            this.selDateRange = [moment(this.course.created).startOf('day').valueOf(), moment(this.course.offline_data.last_class_end).endOf('day').valueOf()]
        } else {
            this.selDateRange = [moment().startOf('month').valueOf(), moment().endOf('month').valueOf()]
        }
        this.realDateRange = [...this.selDateRange]
    }

    fetchCourseDashboard() {
        //  Reset
        this.$nextTick(() => {
            this.selNbStuOption = 0
            this.selRevenueOption = 0
        })
        this.totalRevenue = 0
        this.totalNbStu = 0
        this.totalAtt = ''

        // --   Common
        this.paid_records = []
        //  --  Live
        this.attendances = {}
        this.retention = []
        //  --  Online
        this.progress = []
        //  --  Trial
        this.conversion = []

        const start = this.realDateRange[0]
        const end = this.realDateRange[1]

        let query = {}
        if (!!start && !!end) {
            query = {
                start: start,
                end: end,
            }
        }
        switch (this.courseType) {
            case CourseType.LIVE:
                return this.fetchCourseDashboardOffline(query)
            case CourseType.ONLINE:
                this.selDateRange = []
                this.realDateRange = []
                return this.fetchCourseDashboardOnline(query)
            case CourseType.TRIAL:
                return this.fetchCourseDashboardTrial(query)
        }
    }

    async fetchCourseDashboardOffline(startEnd) {
        this.isLoading = true
        const res = await createRequest(`/dashboard/course/${this.courseId}/offline`, 'get', {
            ...startEnd,
            version: this.version
        }).send()
        this.paid_records = res.data.paid_records || []
        this.attendances = res.data.attendances || {}
        this.retention = res.data.retention || {}
        this.isLoading = false
    }

    async fetchCourseDashboardOnline(startEnd) {
        this.isLoading = true
        const res = await createRequest(`/dashboard/course/${this.courseId}/online`, 'get', {
            ...startEnd,
            version: this.version
        }).send()
        this.paid_records = res.data.paid_records || []
        this.progress = res.data.progress || []
        this.isLoading = false
    }

    async fetchCourseDashboardTrial(startEnd) {
        this.isLoading = true
        const res = await createRequest(`/dashboard/course/${this.courseId}/trial`, 'get', {
            ...startEnd,
            version: this.version
        }).send()
        this.paid_records = res.data.paid_records || []
        this.attendances = res.data.attendances || []
        this.conversion = res.data.conversion || []
        this.isLoading = false
    }

    datePickerChanged() {
        //  make sure start end is 00:00 & 23:59
        this.realDateRange = [moment(this.selDateRange[0]).startOf('day').valueOf(), moment(this.selDateRange[1]).endOf('day').valueOf()]
    }

    preparePaidRecordsData(periodOpt, reduceFunc) {
        try {
            //  Calculate total student
            this.totalNbStu = _.uniq(_.map(this.paid_records, 'member_id')).length
            //  Calculate total revenue
            this.totalRevenue = _.reduce(this.paid_records, (result, item) => {
                return result + (item.amount || 0)
            }, 0)

            if (periodOpt === 'Class') {
                const grouped = _.groupBy(this.paid_records, 'class')

                let category: any[] = []
                if (!_.isNil(this.course.offline_data.classes)) {
                    category = _.map(_.keys(grouped), item => {
                        return `[${item}]`
                    })
                }

                return {
                    category: category,
                    data: _.map(_.values(grouped), group => {
                        return _.reduce(group, (result, item) => {
                            return result + reduceFunc(item)
                        }, 0)
                    })
                }
            } else {
                const recordsByDate = _.sortBy(this.paid_records, ['paid_time'])

                let periodStep: PeriodStep = PeriodStep.DAY
                if (periodOpt === 'Week') {
                    periodStep = PeriodStep.WEEK
                } else if (periodOpt === 'Month') {
                    periodStep = PeriodStep.MONTH
                }

                /**
                 * 1. Extract first & last from the sorted data list which get the earliest time & latest time
                 * 2. Loop by period
                 * 3. Generate data accordingly
                 */
                const allDate: string[] = []
                let dataResult: {} = {}
                let dateRange = [...this.realDateRange]
                if (!this.realDateRange.length && !this.realDateRange[0] && !this.realDateRange[1]) {
                    const last:any = _.last(recordsByDate)
                    dateRange = [recordsByDate[0].paid_time, last ? last.paid_time : Date.now()]
                }

                const allPeriodStart = Chart.generatePeriodStart(dateRange[0], dateRange[1], periodStep)

                for (const periodStart of allPeriodStart) {
                    //  Prepare data
                    const matchPeriod = _.remove(recordsByDate, item => moment(periodStart).isSame(item.paid_time, Chart.transformUnit(periodStep)))
                    const dailyTotal = _.reduce(matchPeriod, (result, item) => {
                        return result + reduceFunc(item)
                    }, 0)

                    // Prepare date label
                    const dateLabel = Chart.formatPeriodLabel(periodStart, dateRange[0], dateRange[1], periodStep)
                    allDate.push(dateLabel)

                    dataResult = {
                        ...dataResult,
                        [dateLabel]: dailyTotal
                    }
                }

                return {
                    category: allDate,
                    data: _.values(dataResult)
                }
            }
        } catch (e) {
            console.error(e)
        }
    }

    @Watch('courseType')
    async watchCourseType(newVal, oldVal) {
        if (newVal !== oldVal) {
            await this.fetchCourseDashboard()
        }
    }

    @Watch('realDateRange')
    async watchRealDateRange(newVal, oldVal) {
        if (!_.isEqual(newVal, oldVal)) {
            await this.fetchCourseDashboard()
        }
    }
}

