import { render, staticRenderFns } from "./course-id-report.vue?vue&type=template&id=33279e69&scoped=true&"
import script from "./course-id-report.vue?vue&type=script&lang=ts&"
export * from "./course-id-report.vue?vue&type=script&lang=ts&"
import style0 from "./course-id-report.vue?vue&type=style&index=0&id=33279e69&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33279e69",
  null
  
)

export default component.exports