









































import PageHeader from '~/components/app/page-header.vue'
import CourseTypeSelect from '~/components/autocomplete/course-type-select.vue'
import CourseData from '~/components/course/course-data'
import {Course, CourseType} from '~/components/data-class/data-class'
import DatePickerShortcutMixin from '~/mixins/date-picker-shortcut-mixin'
import {ReportPermission} from '~/utils/permissions'
import Classes from '~/views/report/course-id/classes.vue'
import Orders from '~/views/report/course-id/orders.vue'
import Performance from '~/views/report/course-id/performance.vue'
import {range} from 'element-ui/src/utils/date-util'
import _ from 'lodash'
import {mixins} from 'vue-class-component'
import {Component} from 'vue-property-decorator'
import {VChip, VChipGroup, VCol, VContainer, VRow, VSelect, VTab, VTabItem, VTabs, VTabsItems} from 'vuetify/lib'

@Component({
    components: {
        CourseTypeSelect,
        Performance,
        Classes,
        Orders,
        PageHeader,
        VTab,
        VTabs,
        VChipGroup,
        VChip,
        VSelect,
        VContainer,
        VRow,
        VCol,
        VTabsItems,
        VTabItem,
    }
})
export default class CourseIdReport extends mixins(DatePickerShortcutMixin) {
    static permission = ReportPermission.CourseReport

    //  META DATA
    CourseType = CourseType
    //  UI DATA
    currTab: number = 0

    selVersion: number = 1
    selCourseType: string = ''

    //  DATA
    courseId: string = ''
    course: Course = new Course()

    versionOption : any[] = []

    get hideCourseType() {
        let list:CourseType[]
        if (!this.course.offline) {
            list = [CourseType.LIVE, CourseType.TRIAL]
        } else if (this.course.offline_data.trial){
            list = [CourseType.ONLINE, CourseType.LIVE]
        } else {
            list = [CourseType.TRIAL]
        }
        return list
    }

    async created() {
        this.courseId = this.$route.params.id
        this.course = await CourseData.shouldGetCourse(this.courseId)

        let defaultSelType
        if (!this.course.offline) {
            defaultSelType = CourseType.ONLINE
        } else if (this.course.offline_data.trial){
            defaultSelType = CourseType.TRIAL
        } else {
            defaultSelType = CourseType.LIVE
        }
        this.selCourseType = defaultSelType

        _.range(0, this.course.current_version).forEach((current) => {
            this.versionOption.push(
                {
                    name: `Version ${current + 1}`,
                    value: current + 1
                }
            )
        })
        this.selVersion = _.last(this.versionOption).value || 1
    }
}
