


















































































import CourseData from '~/components/course/course-data'
import {CourseType} from '~/components/data-class/data-class'
import WtsBtn from '~/components/wts-btn.vue'
import DatePickerShortcutMixin from '~/mixins/date-picker-shortcut-mixin'
import {createRequest} from '~/utils/network-request'
import moment from 'moment'
import {mixins} from 'vue-class-component'
import {Component, Prop, Watch} from 'vue-property-decorator'
import {VCol, VContainer, VDataFooter, VDataTable, VRating, VRow} from 'vuetify/lib'

@Component({
    components: {
        VContainer,
        VRow,
        VCol,
        VDataTable,
        VDataFooter,
        VRating,
        WtsBtn
    }
})
export default class Orders extends mixins(DatePickerShortcutMixin) {
    @Prop() courseId: string
    @Prop() version: number
    @Prop() courseType: CourseType

    //  META DATA

    //  UI DATA
    tableHeaders = [
        {text: 'Order ID', value: 'order_id', sortable: false},
        {text: 'Date', value: 'paid_time'},
        {text: 'Name', value: 'display_name', sortable: false},
        {text: 'Progress', value: 'progress', width: 120},
        {text: 'Rating', value: 'review.rating', width: 110},
        {text: 'Review', value: 'review.comment', width: 450, sortable: false},
        {text: 'Amount', value: 'amount'},
    ]

    selDateRange: number[] = []


    //  DATA
    orderRecords: any[] = []
    totalOrderRecords: number = 0


    get pickerOptions(): { shortcuts: Array<{ text: string, onClick: {} }> } {
        return {
            shortcuts: this.datePickerShortcuts
        }
    }

    async created() {
        const course = await CourseData.shouldGetCourse(this.courseId)

        await this.fetchCourseOrders(0, 0)
    }

    async fetchCourseOrders(start, end) {
        let params: any = {
            version: this.version,
            offline: this.courseType !== CourseType.ONLINE
        }
        if (!!start && !!end) {
            params = {
                ...params,
                start: start,
                end: end
            }
        }
        const res = await createRequest(`/course/${this.courseId}/report`, 'get', params).send()
        this.orderRecords = res.data.report.details
        this.totalOrderRecords = res.data.report.total_enroll
    }

    async datePickerChanged() {
        const start = moment(this.selDateRange[0]).valueOf()
        const end = moment(this.selDateRange[1]).valueOf()
        await this.fetchCourseOrders(start, end)
    }

    @Watch('courseType')
    async watchCourseType(newVal, oldVal) {
        if (newVal !== oldVal) {
            await this.fetchCourseOrders(0, 0)
        }
    }
}

