var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-content-end py-4",attrs:{"cols":"12"}},[_c('el-date-picker',{staticStyle:{"max-width":"250px"},attrs:{"picker-options":_vm.pickerOptions,"align":"right","end-placeholder":"End","range-separator":"-","start-placeholder":"Start","type":"daterange","unlink-panels":""},on:{"change":_vm.datePickerChanged},model:{value:(_vm.selDateRange),callback:function ($$v) {_vm.selDateRange=$$v},expression:"selDateRange"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"card "},[_c('div',{staticClass:"card-body"},[_c('h4',[_vm._v("Orders")]),_c('v-data-table',{attrs:{"footer-props":{itemsPerPageOptions: [ 5, 10, 15, 20, -1 ], itemsPerPageText: 'Items pre page:'},"headers":_vm.tableHeaders,"items":_vm.orderRecords,"items-per-page":20},scopedSlots:_vm._u([{key:"item.order_id",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"s2",attrs:{"to":("/order/" + (item.order_id))}},[_vm._v(" "+_vm._s(item.order_id)+" ")])]}},{key:"item.display_name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"s2",attrs:{"to":("/member/" + (item.member_id))}},[_vm._v(" "+_vm._s(item.display_name)+" ")]),_c('br'),(item.phone)?_c('wts-btn',{attrs:{"value":item.phone}}):_vm._e()]}},{key:"item.paid_time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.paid_time).toLocaleDateString('en-GB'))),_c('br'),_vm._v(" "+_vm._s(new Date(item.paid_time).toLocaleTimeString('en-GB'))+" ")]}},{key:"item.progress",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(((item.progress || 0) * 100).toFixed(2))+"% ")]}},{key:"item.review.rating",fn:function(ref){
var item = ref.item;
return [(item.review)?_c('div',{staticClass:"d-flex align-items-center"},[_c('v-rating',{attrs:{"value":1,"background-color":"grey lighten-2","color":"warning","dense":"","half-increments":"","length":"1","readonly":"","size":"16"}}),_vm._v(" "+_vm._s(item.review.rating)+" ")],1):_c('div',[_vm._v("-")])]}},{key:"item.review.comment",fn:function(ref){
var item = ref.item;
return [(item.review)?_c('div',[_vm._v(_vm._s(item.review.comment))]):_c('div',[_vm._v("-")])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [(item.amount===0)?_c('div',[_vm._v(" Free ")]):_c('div',[_vm._v(" $"+_vm._s(item.amount.toFixed(2))+" ")])]}}])})],1)])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }