






import {ApexOptions} from 'apexcharts'
import _ from 'lodash'
import {Component, Prop, Vue} from 'vue-property-decorator'

@Component
export default class BarChart extends Vue {
    @Prop({default: 250}) height: number
    @Prop() config: {}
    @Prop({default: () => []}) data: any[]
    @Prop({default: () => []}) categories: string[]

    @Prop({default: false}) isPercent: boolean
    @Prop({default: false}) isMoney: boolean
    @Prop({default: 1}) float: number

    showChart: boolean = false

    get series() {
        const formatted = _.map(this.data, item => {
            const tmp = item
            tmp.data = _.map(tmp.data, item => _.round(item, this.float))
            return tmp
        })

        return formatted || []
    }

    get chartOptions() {
        let config: any = {
            chart: {
                type: 'bar',
                height: this.height || 250
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '80%'
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
            },
            xaxis: {
                categories: this.categories
            },
            yaxis: {
                // title: {
                //     text: '$ (thousands)'
                // },
            },
            fill: {
                opacity: 1
            },
            tooltip: {
                y: {
                    formatter: val => {
                        if (this.isPercent)
                            return val + '%'
                        if (this.isMoney)
                            return '$' + val
                        return val
                    }
                }
            }
        }

        if (this.isPercent) {
            config.yaxis.max = 100
        }

        if (this.config) {
            config = _.defaultsDeep({}, config, this.config)
        }

        return config
    }


    mounted() {
        this.showChart = true
    }
}
